



























































import { defineComponent, ref, watch } from '@vue/composition-api'
import { useActions, useGetters } from '@u3u/vue-hooks'
import axios from 'axios'

import { getApiUrl } from '@/inc/app.config'

import VCheckbox from '@/components/form/VCheckbox.vue'
import TipPanel from '@/components/TipPanel.vue'

export default defineComponent({
  name: 'rgpd',
  components: {
    VCheckbox,
  },
  props: {
    url: String,
    checked: Boolean,
  },
  setup(props, ctx) {
    const { showPanel } = useActions('sidePanel', ['showPanel'])

    const gdprConsent = ref(false)

    if (props.checked) {
      gdprConsent.value = props.checked
    }

    const showRgpd = e => {
      e.preventDefault()
      axios.get(`${getApiUrl()}/faq/${props.url}/`).then(({ data }) => {
        showPanel({
          component: { template: TipPanel },
          content: data.content,
        })
      })
    }

    watch(gdprConsent, _ => {
      ctx.emit('input', gdprConsent.value)
    })

    return {
      gdprConsent,
      ...useGetters(['chrome']),
      showRgpd,
    }
  },
})
